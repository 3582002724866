<template>
  <div class="row">
    <div class="col-12">
      <div class="page-title-box">
        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><a id="title2">系统管理</a></li>
            <li class="breadcrumb-item active" id="title3">修改密码</li>
          </ol>
        </div>
        <h4 class="page-title" id="title1">修改密码</h4>
      </div>
    </div>
    <el-form ref="formData" :rules="rules" :model="formData" label-width="80px"
      style="margin-top: 20px;margin-left: 20px;width: 40%;">
      <el-form-item label="原密码" prop="by1">
        <el-input type="password" v-model="formData.by1"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="by2">
        <el-input type="password" v-model="formData.by2"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="by3">
        <el-input type="password" v-model="formData.by3"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :loading="btnLoading" @click="save" icon="el-icon-upload">保存</el-button>
      </el-form-item>
    </el-form>


  </div>
</template>
<script>
import request, { base } from "../../../utils/http";
export default {
  name: 'Password',
  components: {},
  data() {
    return {
      btnLoading: false,//保存按钮加载状态
      formData: {},
      rules: {
        by1: [
          { required: true, message: '请输入原密码', trigger: 'blur' }
        ],
        by2: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        by3: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { validator: (rule, value, callback) => { if (value !== this.formData.by2) { callback(new Error('两次输入密码不一致')); } else { callback(); } }, trigger: 'blur' }
        ]
      }
    };
  },

  methods: {

    //保存
    save() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          this.btnLoading = true;

          var user = JSON.parse(sessionStorage.getItem('user')); //获取用户信息


          var role = sessionStorage.getItem('role'); //获取身份

          let url = ''; //请求地址

          url = base + '/admin/updatePwd';
          this.formData.adminid = user.adminid;


          request.post(url, this.formData).then(res => { //修改密码
            this.btnLoading = false;


            if (res.code == 200) {
              this.btnLoading = false;
              this.formData = {};
              this.$message({
                message: '操作成功',
                type: 'success',
                offset: 320
              });

            } else if (res.code == 201) {
              this.$message({
                message: '原密码错误！',
                type: 'error',
                offset: 320
              });
            }
            else {
              this.btnLoading = false;
              this.$message({
                message: '服务器错误',
                type: 'error',
                offset: 320
              });
            }
          });
        } else {
          return false;
        }
      });
    }
  }
};

</script>
<style scoped></style>
 

